

import { Component, OnInit } from '@angular/core';
import { AutoLogoutService } from './services/auto-logout.service';  // Import the AutoLogoutService

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private autoLogoutService: AutoLogoutService) {}

  ngOnInit(): void {
    // Start the auto-logout timer when the app is initialized
    this.autoLogoutService.initializeInactivityListener();
  }
}
