import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  private inactivityTimeout: any;
  private readonly TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

  constructor(private router: Router) {
    this.initializeInactivityListener(); // Initialize the inactivity listener
  }

  // Method to start the inactivity listener
  initializeInactivityListener() {
    // Reset inactivity timeout on any user activity (click, mousemove, keydown)
    ['click', 'mousemove', 'keydown'].forEach((event) => {
      window.addEventListener(event, () => this.resetInactivityTimeout());
    });

    // Start the inactivity timer when the app loads
    this.resetInactivityTimeout();
  }

  // Method to reset inactivity timeout
  private resetInactivityTimeout() {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(() => this.logout(), this.TIMEOUT_DURATION);
  }

  // Logout method that removes the token and redirects to the login page
  private logout() {
    // Remove token from local storage (or sessionStorage)
    // sessionStorage.removeItem('authToken');
    sessionStorage.clear()
    // Redirect to login page
    this.router.navigate(['/login']);
  }
}
